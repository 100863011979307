.colorPopup {
  border-radius: 4px;
  padding: 13px 15px;
}

.colorPopup.dark {
  background: #113357;
  color: rgba(255, 255, 255, 0.7);
}

.colorPopup.light {
  background: #fff;
  color: #333;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
